import React from 'react';

const BoxPrice = ({price, analog}) => {
	return (
		<div className="analog-price">
			<span className={analog && "price__old"}>{Math.floor(price)}.
					<span className="decimals">
						{(price % 1 * 100) ? (price % 1 * 100).toFixed(0) : '00'}
						{!analog && <span>{' '}грн</span>
					}</span>
			</span>
			{analog && (
				<span className="price__new">
					{analog.price.toFixed(0)}.
				<span className="decimals">
					{(analog.price % 1 * 100) ? (analog.price % 1 * 100).toFixed(0) : '00'} грн
				</span>
				</span>
			)}
		</div>
	);
};

export default BoxPrice;
