import chocolateCandies from "./chocolateCandies";
import caramel from "./caramel";
import wrapper from "./wrapper";
import chocolate from "./chocolate";

export default {
  wrapper,
  chocolateCandies,
  chocolate,
  caramel,
};
