import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';

import Basket from '../Basket';
import GiftsBoard from '../GiftsBoard';
import WrappersBoard from '../WrappersBoard';
// import { sendwebStatsLeadEvent } from "../../utils/webStats";

const tabs = [ 'chocolateCandies', 'caramel', 'chocolate', 'wrapper', 'basket' ];
class Layout extends Component {
    constructor(props) {
        super(props);

        this.state = {
            tab: 'chocolateCandies',
            showButton: true
        };
    }

    handleActiveTab = e => {
        e.preventDefault();
        const tab = e.currentTarget.dataset.name;
        const showButton = !(tab === tabs[tabs.length - 1]);

        window.scroll(0, 70);
        this.setState({ tab, showButton });
        // sendwebStatsLeadEvent({ event: 'ViewContent', payload: { content_name: tab }});
    };

    hanleNextTabButton = (e) => {
        e.preventDefault();
        const { tab } = this.state;
        const index = tabs.findIndex(item => item === tab);
        const showButton = !(tabs[index + 1] === tabs[tabs.length - 1]);

        window.scroll(0, 70);
        // sendwebStatsLeadEvent({ event: 'ViewContent', payload: { content_name: tab }});

        return this.setState({ tab: tabs[index + 1], showButton });
    };

    getActiveTabView = name => {
        switch (name) {
            case 'basket': return <Basket />;
            case 'wrapper': return <WrappersBoard giftGroupName={name} />;
            default: return <GiftsBoard giftGroupName={name} />
        }
    };

    componentDidMount() {
        window.scroll(0, 0);
    }

    render() {
        const { tab, showButton } = this.state;
        const { basket } = this.props;
        const isBasketEmpty = basket.purchase && basket.purchase.length === 0;
        let AllPrice = 0;
        let AllWeight = 0;

        if (!isBasketEmpty) {
            basket.purchase && basket.purchase.forEach(element => {
                AllPrice += element.price * element.count;
                AllWeight += (element.weight || 0) * element.count;
            });
        }

        return (
            <div className='container'>
                <h1 className="display-4 text-center header-name header-text">Додайте цукерки - складіть подарунок</h1>
                <br />
                <p className='text-center'>Миколай: <a href="tel:067-555-13-08">067-555-13-08</a></p>
                <ul className="nav nav-pills nav-fill custom-menu">
                    <li className="nav-item" data-name='chocolateCandies' onClick={this.handleActiveTab} >
                        <a className={`nav-link ${tab === 'chocolateCandies' ? "active" : ""}`} href="/">Шоколадні цукерки</a>
                    </li>
                    <li className="nav-item" data-name='caramel' onClick={this.handleActiveTab} >
                        <a className={`nav-link ${tab === 'caramel' ? "active" : ""}`} href="/">Карамель</a>
                    </li>
                    <li className="nav-item" data-name='chocolate' onClick={this.handleActiveTab} >
                        <a className={`nav-link ${tab === 'chocolate' ? "active" : ""}`} href="/">Шоколад</a>
                    </li>
                    <li className="nav-item" data-name='wrapper' onClick={this.handleActiveTab} >
                        <a className={`nav-link ${tab === 'wrapper' ? "active" : ""}`} href="/">Упаковка</a>
                    </li>
                    <li className="nav-item" data-name='basket' onClick={this.handleActiveTab} >
                        <a className={`nav-link ${tab === 'basket' ? "active" : ""}`} href="/">Ваше замовлення({basket && basket.purchase && basket.purchase.length})</a>
                    </li>
                </ul>
                <div className="board">
                    {this.getActiveTabView(tab)}
                    {
                        showButton &&
                        <div className='mobile-next-block'>
                            <button type="button" onClick={this.hanleNextTabButton} className="btn btn-secondary">Далі →</button>
                        </div>
                    }
                </div>
                <div className='mobile-purchase-helper'>
                    <span>
                        Ціна: {AllPrice.toFixed(2)} грн
                        &nbsp;|
              Вага: {AllWeight || 0} г
                        &nbsp;
            </span>
                </div>
            </div>
        );
    }
}

Layout.propTypes = {
    basket: PropTypes.object.isRequired
};

const mapStateToProps = ({ basket }) => ({ basket });
const withConnect = connect(mapStateToProps);

export default compose(withConnect)(Layout);
