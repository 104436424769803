import candies from './ASSORTMENT';

export default {
    id: 'solodka-hatynka',
    name: '«Солодка хатинка»',
    price: 70.79,
    weight: 265,
    image: 'giftBox/01-Sladkii_domik-1507.webp',
    analog: {
        name: '«Солодка хатинка»',
        image: 'giftBox/rizdvDim.webp',
        price: 60,
    },
    content: [
        {
            ...candies.chervonyiMak,
            qantity: 3,
        },
        {
            ...candies.slivkiLenivki,
            qantity: 1,
        },
        {
            ...candies.candyNutArahis,
            qantity: 2,
        },
        {
            ...candies.romashka,
            qantity: 2,
        },
        {
            ...candies.milkySplash,
            qantity: 2,
        },
        {
            ...candies.nuga,
            qantity: 2,
        },
        {
            ...candies.galeretkiApelsin,
            qantity: 3,
        },
        {
            ...candies.sunnyZhuk,
            qantity: 3,
        },
        {
            ...candies.lolipopYogurt,
            qantity: 2,
        }
    ],
};
