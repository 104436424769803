import candies from './ASSORTMENT';

export default {
    id: 'novorichnyi-kamin',
    name: '«Новорічний камін»',
    price: 175.21,
    weight: 600,
    image: 'giftBox/novogodniy_kamin.webp',
    analog: {
        name: '«Новорічний вогник»',
        price: 159,
        image: 'giftBox/0nash-ny-vognyk.webp',
    },
    content: [
        {
            ...candies.chervonyiMak,
            qantity: 3,
        },
        {
            ...candies.karakum,
            qantity: 3,
        },
        {
            ...candies.slivkiLenivki,
            qantity: 2,
        },
        {
            ...candies.candyNutArahis,
            qantity: 4,
        },
        {
            ...candies.romashka,
            qantity: 4,
        },
        {
            ...candies.milkySplash,
            qantity: 4,
        },
        {
            ...candies.nuga,
            qantity: 4,
        },
        {
            ...candies.galeretkiApelsin,
            qantity: 4,
        },
        {
            ...candies.sunnyZhuk,
            qantity: 3,
        },
        {
            ...candies.lolipopYogurt,
            qantity: 3,
        },
        {
            ...candies.chokoBatonCaramel,
            qantity: 1,
        },
        {
            ...candies.yummiGummiMiniBear,
            qantity: 1,
        },
        {
            ...candies.didMoroz,
            qantity: 1,
        },
    ],
};
