import candies from './ASSORTMENT';

export default {
    id: 'godivnytsya-dlya-ptahiv',
    name: '«Годівниця для птахів»',
    price: 271.21,
    weight: 850,
    image: 'giftBox/14-GODIVNITSA_2020.webp',
    analog: {
        name: '«Годівниця для птахів»',
        image: 'giftBox/sumochkaVAssort.webp',
        price: 209,
    },
    content: [
        {
            ...candies.chervonyiMak,
            qantity: 4,
        },
        {
            ...candies.karakum,
            qantity: 4,
        },
        {
            ...candies.slivkiLenivki,
            qantity: 3,
        },
        {
            ...candies.candyNutArahis,
            qantity: 6,
        },
        {
            ...candies.romashka,
            qantity: 5,
        },
        {
            ...candies.milkySplash,
            qantity: 4,
        },
        {
            ...candies.sorrento,
            qantity: 5,
        },
        {
            ...candies.toffelini,
            qantity: 3,
        },
        {
            ...candies.galeretkiApelsin,
            qantity: 3,
        },
        {
            ...candies.nuga,
            qantity: 5,
        },
        {
            ...candies.yummiGummiMiniBear,
            qantity: 1,
        },
        {
            ...candies.lolipopYogurt,
            qantity: 3,
        },
        {
            ...candies.chokoBatonCaramel,
            qantity: 1,
        },
        {
            ...candies.didMoroz,
            qantity: 1,
        }
    ],
};
