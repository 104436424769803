import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose, bindActionCreators } from 'redux';

import BasketRow from '../BasketRow';
import OrderModal from '../OrderModal';
import { incrementGift, decrementGift } from '../../actions';

class Basket extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false
    };
  }

  handleOpenModal = () => {
    this.setState({ showModal: true });
  };

  handleCloseModal = () => {
    this.setState({ showModal: false });
  };

  handleOnChange = e => {
    const count = e.target.value;

    this.props.incrementGift(count);
  }

  render () {
    const { basket } = this.props;
    const isBasketEmpty = basket.purchase && basket.purchase.length === 0;
    let AllPrice = 0;
    let AllWeight = 0;
    const { showModal } = this.state;
    const wrappers = [];
    const items = [];

    basket.purchase && basket.purchase.forEach(item => {
      item.id.match('wrapper') ? wrappers.push(item) : items.push(item)
    });

    if (!isBasketEmpty) {
      basket.purchase && basket.purchase.forEach(element => {
        AllPrice += element.price * element.count;
        AllWeight += (element.weight || 0) * element.count;
      });
    }

    return (
      <div>
        {
          !isBasketEmpty &&
          <React.Fragment>
            <p className='desktop-purchase'>
              Ціна подарунку: {AllPrice.toFixed(2)} грн
              &nbsp;
              Вага подарунку: {AllWeight} г
            </p>
          </React.Fragment>
        }
        <br />
        <p className="text-center font-weight-bold">Асортимент подарунку</p>
        <div className="table-responsive-sm">
          <table className="table">
            <thead>
              <tr>
                <th className="text-center">Назва</th>
                <th className="center">Вигляд</th>
                <th className="text-center">Вартість</th>
                <th className="text-center">Кількість</th>
                <th className="text-center">Вага</th>
                <th className="text-center">Дія</th>
              </tr>
            </thead>
            <tbody>
              {
                items.length === 0 ?
                <tr>
                  <td colSpan={5}>Ваше замовлення пусте</td>
                </tr>
                :
                items.map((item, index) => {
                  return (
                    <BasketRow gift={item} key={index} />
                  );
                })
              }
            </tbody>
          </table>
        </div>
        <br />
        <p className="text-center font-weight-bold">Упаковка</p>
        <div className="table-responsive-sm">
          <table className="table">
            <thead>
              <tr>
                <th className="text-center">Назва</th>
                <th className="center">Вигляд</th>
                <th className="text-center">Вартість</th>
                <th className="text-center">Кількість</th>
                <th className="text-center">Вміщує</th>
                <th className="text-center">Дія</th>
              </tr>
            </thead>
            <tbody>
              {
                wrappers.length === 0 ?
                <tr>
                  <td colSpan={5}>Ви ще не обрали упаковку</td>
                </tr>
                :
                wrappers.map((item, index) => {
                  return (
                    <BasketRow gift={item} key={index} />
                  );
                })
              }
            </tbody>
          </table>
        </div>
        {
            !isBasketEmpty &&
            <div className="card text-center">
            {
              showModal &&
              <OrderModal onCloseModal={this.handleCloseModal} />
            }
              <div className="card-body">
              <div className="purchase-wrapper">
                <p className="text-center">
                  Введіть кількість подарунків:
                </p>
                <input type="text" className="form-control text-center purchase-input-group" onChange={this.handleOnChange} value={basket.gift_count} aria-describedby="button-addon3" />
                <p className="purchase-total-count">
                  Загальна сума: {(AllPrice * basket.gift_count).toFixed(2)} грн
                </p>
              </div>
                <button type="button" disabled={AllPrice * basket.gift_count < 3000} onClick={this.handleOpenModal} className="btn btn-success">Замовити</button>
              </div>
              <small>Мінімальна сума замовлення: 3000 грн</small>
            </div>
          }
      </div>
    );
  }
}

Basket.propTypes = {
  basket: PropTypes.object.isRequired,
  incrementGift: PropTypes.func.isRequired,
  decrementGift: PropTypes.func.isRequired
};

const mapStateToProps = ({ basket }) => ({ basket });
const mapDispatchToProps = dispatch => bindActionCreators({ incrementGift, decrementGift }, dispatch);
const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(Basket);
