export default {
    ekler: {
        name:'Еклер',
        price: 1.05,
        weight: 6,
        img_name:'gifts/ekler.webp',
        count: 0
    },
    fangenta: {
        name:'Фанджента',
        price: 2.1,
        weight: 14,
        img_name:'gifts/fangenta.webp',
    },
    stakanMoloka: {
        name:'Стакан Молока',
        price: 1.55,
        weight: 7,
        img_name:'gifts/stakan.webp',
    },
    toffesa: {
        name:'Тоффеса',
        price: 0.85,
        weight: 8,
        img_name:'gifts/toffesa.webp',
    },
    toffelini: {
        name:'Тоффеліні',
        price: 1.55,
        weight: 8,
        img_name:'gifts/toffelini.webp',
    },
    minkibinki: {
        name:'Мінкі Бінкі',
        price: 1.55,
        weight: 10,
        img_name:'gifts/minkibinki.webp',
    },
    bdgilka: {
        name:'Шалена бджілка',
        price: 1.15,
        weight: 7,
        img_name:'gifts/bdgilka.webp',
    },
    gely: {
        name:'Джеллі',
        price: 1.35,
        weight: 10,
        img_name:'gifts/gely.webp',
    },
    jamis: {
        name:'Ямміс',
        price: 0.95,
        weight: 7,
        img_name:'gifts/jamis.webp',
    },
    rachki: {
        name:'Рачки',
        price: 1.05,
        weight: 7,
        img_name:'gifts/rachki.webp',
    },
    milkKraplia: {
        name:'Молочна крапля',
        price: 1.11,
        weight: 7,
        img_name:'gifts/milk_kraplia.webp',
    },
    coffeelike: {
        name:'Кофе Лайк',
        price: 1.11,
        weight: 7,
        img_name:'gifts/coffeelike.webp',
    },
    jogurtni: {
        name:'Йогуртіні',
        price: 0.95,
        weight: 7,
        img_name:'gifts/jogurtni.webp',
    },
    kislinka: {
        name:'Кислинка',
        price: 0.75,
        weight: 5,
        img_name:'gifts/kislinka.webp',
    },
    chokoBatonchyk: {
        name:'Шоколадний батончик',
        price: 13.85,
        weight: 43,
        img_name:'gifts/chocolate_6.webp',
    },
    tidbit: {
        name:'Tidbit',
        price: 14.90,
        weight: 50,
        img_name:'gifts/tidbit.webp',
    },
    milkCoconut: {
        name:'Молочний з кокосовою нугою',
        price: 24,
        weight: 90,
        img_name:'gifts/milk-coconut.webp',
    },
    darkAir: {
        name:'Пористий екстрачорний',
        price: 32.5,
        weight: 80,
        img_name:'gifts/dark-air.webp',
    },
    extraDark: {
        name:'Екстрачорний з цілим горіхом',
        price: 43.7,
        weight: 90,
        img_name:'gifts/extra-dark.webp',
    },
    chaika: {
        name: 'Чайка',
        price: 18.90,
        weight: 90,
        img_name:'gifts/chaika.webp',
    },
    milkExtraBubble: {
        name: 'Молочний пористий з шоколадно-горіховою начинкою ',
        price: 23.75,
        weight: 85,
        img_name:'gifts/milk-extra-bubble-nut.webp',
    },
    bubbleCrisp: {
        name: 'Пористий кріспі ',
        price: 29.9,
        weight: 85,
        img_name:'gifts/porystyiCrispChoco.webp',
    },
    milkExtraKrispi: {
        name: 'Молочний пористий «кріспі» ',
        price: 35.9,
        weight: 85,
        img_name:'gifts/milk-extra-krispi.webp',
    },
    milkPeanut: {
        name: 'Молочний з арахісом',
        price: 35.9,
        weight: 87,
        img_name:'gifts/milk-peanut.webp',
    },
    lacmiBlue: {
        name:'Молочний шоколад',
        price: 35.90,
        weight: 90,
        img_name:'gifts/lacmi_blue.webp',
    },
    lacmiRed: {
        name:'Молочний з вафелькою',
        price: 35.90,
        weight: 100,
        img_name:'gifts/lacmi_red.webp',
    },
    lacmiGreen: {
        name:'Молочний з дробленим горіхом',
        price: 38.9,
        weight: 90,
        img_name:'gifts/lacmi_green.webp',
    },
    kreamel: {
        name:'Креамель',
        price: 3.65,
        weight: 15,
        img_name:'gifts/kreamel.webp',
        deprecated: true,
    },
    granola: {
        name:'Гранола',
        price: 1.95,
        weight: 15,
        img_name:'gifts/granola.webp',
    },
    lishina: {
        name:'Ліщина',
        price: 5,
        weight: 16,
        img_name:'gifts/lishina.webp',
    },
    nutini: {
        name:'Нуттіні',
        price: 2,
        weight: 17,
        img_name:'gifts/nutini.webp',
    },
    shokolapki: {
        name:'Шоколапки',
        price: 1.15,
        weight: 6,
        img_name:'gifts/shokolapki.webp',
    },
    kanafeto: {
        name:'Канафетто',
        price: 1.95,
        weight: 9,
        img_name:'gifts/kanafeto.webp',
    },
    jonhykroker: {
        name:'Джонні Крокер',
        price: 2.2,
        weight: 10,
        img_name:'gifts/jonhykroker.webp',
    },
    croc: {
        name:'Крок',
        price: 2.5,
        weight: 12,
        img_name:'gifts/crock.webp',
    },
    korivka: {
        name:'Корівка',
        price: 2.1,
        weight: 15,
        img_name:'gifts/korivka.webp',
    },
    chervonyiMak: {
        name: 'Червоний мак',
        price: 4,
        weight: 13,
        img_name:'gifts/redmak.webp',
        image: 'gifts/redmak.webp',
        // img_name:'gifts/redmak.webp',
        // image: 'gifts/redmak.webp',
    },
    karakum: {
        name: 'Кара-кум',
        weight: 13,
        price: 4,
        image: 'gifts/karakum.webp',
    },
    romashka: {
        name: 'Ромашка',
        price: 2.7,
        weight: 14,
        img_name:'gifts/romashka.webp',
        image: 'gifts/romashka.webp',
    },
    kokochoko: {
        image: 'gifts/kokochocko.webp',
        name:'Ко-Ко Чоко',
        price: 4.15,
        weight: 14,
        img_name:'gifts/kokochocko.webp',
    },
    korolivskyiSharm: {
        image: 'gifts/korolivskyiSharm.webp',
        name:'Королівський Шарм',
        price: 3.75,
        weight: 10,
        img_name:'gifts/korolivskyiSharm.webp',
    },
    ptashyneMoloko: {
        image: 'gifts/ptashyneMoloko.webp',
        name:'Пташине молоко',
        price: 1.95,
        weight: 11,
        img_name:'gifts/ptashyneMoloko.webp',
    },
    sorrento: {
        name:'Сорренто',
        price: 1.55,
        weight: 8,
        img_name:'gifts/sorrento.webp',
        image: 'gifts/sorrento.webp',
    },
    sorrento14g: {
        name:'Сорренто',
        price: 2.7,
        weight: 14,
        img_name:'gifts/sorrento.webp',
        image: 'gifts/sorrento.webp',
    },
    nuga: {
        name: 'Нуга',
        price: 2.15,
        weight: 11,
        img_name:'gifts/nuga.webp',
        image: 'gifts/nuga.webp',
    },
    candyNutArahis: {
        name: 'Candy Nut м’яка карамель з арахісом',
        price: 5.40,
        weight: 40,
        img_name:'gifts/kendinat.webp',
        image: 'gifts/kendinat.webp',
    },
    candyNut: {
        name:'Кенді Нат',
        price: 8.45,
        weight: 40,
        img_name:'gifts/candynat2.webp',
        image: 'gifts/candynat2.webp',
    },
    candyNut2: {
        name:'Кенді Нат',
        price: 2.75,
        weight: 13,
        img_name:'gifts/kendinat.webp',
        image: 'gifts/kendinat.webp',
    },
    francheska: {
        name:'Франческа',
        price: 1.95,
        weight: 11,
        img_name:'gifts/francheska.webp',
        image: 'gifts/francheska.webp',
    },


    slivkiLenivki: {
        name:'Ліниві Вершки',
        price: 6.25,
        weight: 32,
        img_name:'gifts/lazy_slivki.webp',
        image: 'gifts/lazy_slivki.webp',
    },
    galaretka: {
        name:'Галаретка',
        price: 1.50,
        weight: 12,
        img_name:'gifts/galaretka.webp',
    },
    galeretkiApelsin: {
        name: 'Galaretka апельсин',
        weight: 1,
        price: 1,
        image: 'gifts/galaretka.webp',
        img_name: 'gifts/galaretka.webp',
    },
    yummiGummiMiniBear: {
        name: 'Yummi Gummi mini bear',
        weight: 1,
        price: 1,
        image: 'gifts/ymmi-gummi.webp',
    },
    didMoroz: {
        name: 'Дід мороз',
        weight: 22,
        price: 1,
        image: 'gifts/did-moroz.webp',
    },
    chokoBatonCaramel: {
        name: 'Шоколадний батон Roshen з карамельною начинкою',
        weight: 1,
        price: 1,
        image: 'gifts/chocolate_6.webp',
    },
    milkySplash: {
        name: 'Milky Splash з молочною начинкою',
        weight: 1,
        price: 1,
        image: 'gifts/milky-splash-toffee.webp',
    },
    sunnyZhuk: {
        name: 'Сонячний жук',
        price: 0.75,
        weight: 8,
        image: 'gifts/sunjuk.webp',
        img_name:'gifts/sunjuk.webp',
    },
    monblan: {
        name:'Монблан',
        price: 8.25,
        weight: 17,
        img_name:'gifts/monblan.webp',
    },
    monblanChokoSezam: {
        name: 'Монблан з шоколадом та сезамом',
        weight: 1,
        price: 6.55,
        image: 'gifts/monblan-choco-sezam.webp',
    },
    monblanPralineSmashedNut: {
        name: 'Монблан крем-праліне з подрібненим горіхом',
        weight: 1,
        price: 6.55,
        image: 'gifts/monblan.webp',
    },
    monblanWholeNut: {
        name: 'Монблан з цілим лісовим горіхом',
        weight: 1,
        price: 6.55,
        image: 'gifts/monblan-tselym-orekhom.webp',
    },
    monblanCaramelMigdal: {
        name: 'Монблан з карамелізованим мигдалем',
        weight: 1,
        price: 1,
        image: 'gifts/monblan-caramel-mygdal.webp',
    },
    lolipopGum: {
        name: 'Lollipops Gum',
        price: 3.25,
        weight: 14,
        img_name:'gifts/lolypopsGum.webp',
    },
    lolipopYogurt: {
        name: 'Lollipops з йогуртовими смаками',
        price: 3.25,
        weight: 14,
        img_name: 'gifts/lolypops2.webp',
        image: 'gifts/lolypops2.webp',
    },
    lolipops: {
        name:'Лоллі Попс',
        price: 2.85,
        weight: 14,
        img_name:'gifts/lolypops.webp',
    },


    // wrappers list
    happyNY: {
        name:'З Новим роком',
        price: 4.80,
        contain: 400,
        img_name:'gifts/s4.webp',
    },
    podarunokMykolayaPaket: {
        name:'Подарунок від Миколая',
        price: 3,
        contain: 400,
        img_name:'giftBox/Paket-400-gr.webp',
    },
    symbolNY2022: {
        name:'Символ нового року',
        price: 8.5,
        contain: 400,
        img_name:'giftBox/symbolroku2022.webp',
    },
    symbolNY2023: {
        name:'Символ нового року',
        price: 15,
        contain: 400,
        img_name:'giftBox/symbolNY2023.webp',
    },
    symbolNY2023_1000: {
        name:'Символ нового року 2023',
        price: 12,
        contain: 900,
        img_name:'giftBox/symbolNY2023_900.webp',
    },
    novorichnyiHodynnyk: {
        name:'Новорічний годинник',
        price: 17,
        contain: 1000,
        img_name:'giftBox/wrapper-novorichnyiHodynnyk.webp',
    },
    sumochkaVAssort: {
        name:'Сумочка в асортименті',
        price: 15,
        contain: 1000,
        img_name:'giftBox/sumochkaVAssort.webp',
    },
    veselyhSvyat1k: {
        name:'Сумочка в асортименті',
        price: 20,
        contain: 1000,
        img_name:'giftBox/wrapper-veselyhSvyat1k.webp',
    },
    rizdvBudynok: {
        name:'Різдвяний будинок',
        price: 17,
        contain: 1400,
        img_name:'giftBox/rizdvBudynok.webp',
    },
    vehzaDrakon: {
        name:'Вежа дракон',
        price: 23,
        contain: 1000,
        img_name:'giftBox/wrapper-vehzaDrakon.webp',
    },
    ridnyiDim: {
        name:'Рідний дім',
        price: 8.5,
        contain: 350,
        img_name:'giftBox/rizdvDim.webp',
    },
    podarunokZaychuka: {
        name:'Подарунок від зайчика',
        price: 8.5,
        contain: 400,
        img_name:'giftBox/0nash-Novogodnyaya_sumochka.webp',
    },
    symbolNY2021: {
        name:'Символ Нового року',
        price: 5.50,
        contain: 400,
        img_name:'giftBox/symbolNY2023.webp',
    },
    nyPakunok: {
        name:'Новорічний пакунок',
        price: 3.50,
        contain: 600,
        img_name:'giftBox/ny-pakunok.webp',
    },
    symbolNY2021_1K: {
        name:'Символ Нового року',
        price: 14.25,
        contain: 1000,
        img_name:'giftBox/0nash-portfel-tigercub.webp',
    },
    solodkaHatynka: {
        name:'Солодка хатинка',
        price: 9.50,
        contain: 400,
        img_name:'giftBox/0nash-magic-candy.webp',
    },
    korivkaPeppi: {
        name:'Корівка «Пеппі»',
        price: 5.50,
        contain: 350,
        img_name:'gifts/wrapper_n8.webp',
    },
    podarunokSanty: {
        name:'Подарунок Санти',
        price: 8.5,
        contain: 400,
        img_name:'gifts/wrapper_podarunok_santy.webp',
    },
    sakvoyazh: {
        name:'Саквояж',
        price: 5.5,
        contain: 400,
        img_name:'giftBox/0nash-Novogodnyaya_sumochka.webp',
    },
    bantWrapper: {
        name:'Бант',
        price: 8.25,
        contain: 500,
        img_name:'gifts/wrapper_3.webp',
    },
    bantWrapper800: {
        name:'Бант',
        price: 9.75,
        contain: 800,
        img_name:'gifts/bant800.webp',
    },
    saintNic: {
        name:'Святий Миколай',
        price: 9.5,
        contain: 500,
        img_name:'gifts/wrapper_saint_nic.webp',
    },
    saintNic800: {
        name:'Святий Миколай',
        price: 14,
        contain: 800,
        img_name:'gifts/wrapper_saint_nic2024.webp',
    },
    saintNic1000: {
        name:'Святий Миколай',
        price: 17,
        contain: 1000,
        img_name:'giftBox/0nash-ny-vognyk.webp',
    },
    veselyhSvyat: {
        name:'Веселих свят',
        price: 7.5,
        contain: 400,
        img_name:'gifts/wrapper-veselyh-svyat.webp',
    },
    sunduchokWrappper400: {
        name:'Сундучок',
        price: 9.5,
        contain: 400,
        img_name:'gifts/wrapper_1.webp',
    },
    sunduchok400: {
        name:'Сундучок',
        price: 9.5,
        contain: 400,
        img_name:'giftBox/wrapper-sunduchok400.webp',
    },
    sunduchokWrappper600: {
        name:'Сундучок',
        price: 14.5,
        contain: 600,
        img_name:'gifts/wrapper_1.webp',
    },
    sunduchok600: {
        name:'Сундучок',
        price: 14.5,
        contain: 600,
        img_name:'giftBox/sunduchok600.webp',
    },
    sunduchok800: {
        name:'Скриня',
        price: 15,
        contain: 800,
        img_name:'giftBox/sunduchok600.webp',
    },
    ranetsZaichuk: {
        name:'Ранець «Зайчик»',
        price: 10.5,
        contain: 650,
        img_name:'giftBox/ranetsZaets.webp',
    },
    novorichnyiBant: {
        name:'Новорічний "Бант"',
        price: 15,
        contain: 650,
        img_name:'giftBox/novorichnyiBant.webp',
    },
    ranetsGnom: {
        name:'Ранець «Гном»',
        price: 15,
        contain: 650,
        img_name:'giftBox/ranetsGnom.webp',
    },
    budynokPatron: {
        name:'Будинок «Патрон»',
        price: 15,
        contain: 650,
        img_name:'giftBox/budynokPatron.webp',
    },
    wrapperMerlin: {
        name:'Корівка «Мерілін»',
        price: 8.50,
        contain: 500,
        img_name:'gifts/wrapper_merlin.webp',
    },
    angelsWrapper: {
        name:'Янголята',
        price: 8.75,
        contain: 500,
        img_name:'gifts/wrapper_6.webp',
    },
    tigerCub: {
        name:'Тигреня',
        price: 10.9,
        contain: 600,
        img_name:'giftBox/0nash-ny-yarmarok.webp',
    },
    nyPost: {
        name:'Новорічна пошта',
        price: 12,
        contain: 800,
        img_name:'gifts/wrapper_9.webp',
    },
    happyNY1K: {
        name:'З Новим роком',
        price: 12.75,
        contain: 1000,
        img_name:'gifts/wrapper-happy-ny-1k.webp',
    },
    poshtaMyshenyat: {
        name:'Пошта мишенят',
        price: 7.75,
        contain: 600,
        img_name:'gifts/wrapper_7.webp',
    },
    buronkaWrapper: {
        name:'Корівка «Бурьонка»',
        price: 7.75,
        contain: 600,
        img_name:'gifts/w11.webp',
    },
    lihtarykWrapper: {
        name:'Ліхтарик',
        price: 9.5,
        contain: 600,
        img_name:'gifts/wrapper_lihtaryk.webp',
    },
    lihtarWrapper: {
        name:'Ліхтар',
        price: 9.5,
        contain: 400,
        img_name:'gifts/wrapper-lihtar.webp',
    },
    nyPoshtaWrapper: {
        name:'Новорічна пошта',
        price: 8.75,
        contain: 700,
        img_name:'gifts/wrapper_post.webp',
    },
    vintazh: {
        name:'Вінтаж',
        price: 8.25,
        contain: 700,
        img_name:'gifts/wrapper_post.webp',
    },
    skrynia800: {
        name:'Скриня Діамант',
        price: 10.5,
        contain: 800,
        img_name:'gifts/wrapper_11.webp',
    },
    budynokPatron800: {
        name:'Будинок «Патрон»',
        price: 15,
        contain: 800,
        img_name:'giftBox/budynokPatron.webp',
    },
    zaichykSumochka: {
        name:'Сумочка «Зайчик»',
        price: 10.5,
        contain: 800,
        img_name:'giftBox/symbolNY2023_900.webp',
    },
    veselyiSnigovyk: {
        name:'Веселий сніговик',
        price: 15,
        contain: 800,
        img_name:'giftBox/wrapper-veselyiSnigovyk.webp',
    },
    zelenaSkrynia: {
        name:'Скриня зелена',
        price: 16,
        contain: 900,
        img_name:'giftBox/zelenaSkrynia.webp',
    },
    sumochkaUkraine: {
        name:'Сумочка «Україна»',
        price: 14,
        contain: 800,
        img_name:'giftBox/sumochkaUkraine.webp',
    },
    shchaslyvyhSviat: {
        name:'Щасливих свят',
        price: 15,
        contain: 800,
        img_name:'giftBox/wrapper-shchaslyvyhSviat.webp',
    },
    diamantWrapper: {
        name:'Скриня Діамант',
        price: 9.25,
        contain: 700,
        img_name:'gifts/wrapper_11.webp',
    },
    skryniaWrapper: {
        name:'Скриня Санти',
        price: 9.25,
        contain: 700,
        img_name:'gifts/wrapper_12.webp',
    },
    elfHouseWrapper: {
        name:'Будинок ельфів',
        price: 10.50,
        contain: 800,
        img_name:'gifts/wrapper-elf-house.webp',
    },
    santaHouseWrapper: {
        name:'Будинок Санти',
        price: 14,
        contain: 800,
        img_name:'gifts/wrapper-santaHouseWrapper.webp',
    },
    tigerSumochka: {
        name:'Сумочка «Тигреня»',
        price: 9.50,
        contain: 800,
        img_name:'gifts/wrapper-tiger-sumochka.webp',
    },
    bychokWrapper: {
        name:'Металевий бичок',
        price: 11.75,
        contain: 1000,
        img_name:'gifts/w12.webp',
    },
    nyBellWrapper: {
        name:'Різдвяний дзвін',
        price: 17,
        contain: 1000,
        img_name:'gifts/wrapper_bell.webp',
    },
    snizhnaKorivka: {
        name:'Сніжна корівка',
        price: 11.75,
        contain: 1000,
        img_name:'gifts/skrynyaSkarbuv.webp',
    },
    bearsPortfelWrapper: {
        name:'Портфель ведмежат',
        price: 12.75,
        contain: 1000,
        img_name:'gifts/wrapper_16.webp',
    },
    portfelSantyWrapper: {
        name:'Портфель Санти',
        price: 17,
        contain: 1000,
        img_name:'gifts/wrapper_santa_bag.webp',
    },
    nyBookWrapper: {
        name:'Новорічна книга',
        price: 14.75,
        contain: 1000,
        img_name:'gifts/wrapper_ne_book.webp',
    },
    nyLabirinth: {
        name:'Новорічний лабіринт',
        price: 14.75,
        contain: 1000,
        img_name:'gifts/wrapper_19.webp',
    },
    nyKaminWrapper: {
        name:'Новорічний камін',
        price: 15,
        contain: 1000,
        img_name:'gifts/wrapper_20.webp',
    },
    sunduchokWinterKazka: {
        name:'Зимова казка',
        price: 5.75,
        contain: 500,
        img_name:'giftBox/0nash-ny-vinok.webp',
    },
    tramSnizhok: {
        name:'Трамвайчик «Сніжок» ',
        price: 5.50,
        contain: 350,
        img_name:'gifts/wrapper_22.webp',
    },
}
