import React, { useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import '../../styles.css';
import Slider from 'react-slick';

import phone from './images/call.svg';
import giftBoxes from '../../assets/giftBox';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import OrderModal from '../OrderModalMainScreen';
import BoxPrice from "./components/BoxPrice";
import VModal from "./components/VModal";

const imageDir = 'images/welcome-page'

const breakPoints = (width) => {
      if (width >= 620 && width < 920) return 2;
      if (width >= 920) return 3;

      return 1;
  }

const settings = {
    dots: false,
    infinite: true,
    lazyLoad: true,
    speed: 500,
    slidesToShow: breakPoints(window.innerWidth),
    slidesToScroll: 1,
    centerPadding: '50px',
    initialSlide: breakPoints(window.innerWidth) === 3 ? 7 : 8,
  };

function WelcomePage() {
    const [config, setConfig] = useState(settings);
    const [showModal, setShowModal] = useState(false);
    const [selectedBox, setSelectedBox] = useState({});

    const handleOpenModal = (gBox) => {
        setSelectedBox(gBox);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setSelectedBox({});
        setShowModal(false);
    };

    useEffect(() => {
        window.addEventListener("resize", resizeThrottler, false);

        let resizeTimeout;
        function resizeThrottler() {
            // ignore resize events as long as an actualResizeHandler execution is in the queue
            if ( !resizeTimeout ) {
            resizeTimeout = setTimeout(function() {
                resizeTimeout = null;
                actualResizeHandler();

            // The actualResizeHandler will execute at a rate of 15fps
            }, 66);
            }
        }

        function actualResizeHandler() {
            setConfig((state) => ({
                ...state,
                slidesToShow: breakPoints(window.innerWidth)
            }));
        }

        return () => window.removeEventListener('resize', resizeThrottler);

    }, []);
    return (
        <React.Fragment>
            <section className="hero">
                <div className="background background-1" id="gifts">
                    <div className="hero-container">
                        <div className="top">
                            <h1 className="hero__heading">
                                <span className="hero__heading-top">
                                    новорічні подарунки <br/>
                                    з цукерок ROSHEN
                                </span>
                            </h1>

                            <div className="sliderWrapper">
                                <Slider {...config}>
                                    {giftBoxes.map((gBox) => (
                                        <div key={gBox.id}>
                                            <div className="slider_content">
                                                 <Link to={`/${gBox.id}`}>
                                                    <img alt={gBox.name} className="giftbox__img" src={`images/${gBox.image}`} />
                                                 </Link>
                                                <h2 className="legend giftbox__name" >{gBox.name} - {gBox.weight}г</h2>
                                                <BoxPrice price={gBox.price} analog={gBox.analog}/>
                                                <div className="slide__buttons">
                                                    <button onClick={() => handleOpenModal(gBox)} className="slide__order-button yellow-bg">Замовити</button>
                                                    <Link
                                                        className="slide__details-button"
                                                        // className="btn btn-primary purchase-btn"
                                                        to={`/${gBox.id}`}
                                                    >
                                                        Детальніше
                                                     </Link>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </Slider>
                            </div>
                        </div>
                    </div>
                    {showModal && <OrderModal onCloseModal={handleCloseModal} selectedBox={selectedBox} />}
                    <div className='container' id="2">
                        <div className="text-with-img ">
                            <div className="text-block">
                                <div id="constructor">
                                    <h2 className="text-white text-center text-img-header">
                                        Конструктор <br /> наборів солодощів
                                    </h2>
                                </div>
                                <div className="text-black constructor-desc">
                                    Виберіть цукерки на свій смак
                                    <br/>
                                    <Link to='/purchase' className="btn btn-primary purchase-btn yellow-bg">
                                        Скласти подарунок
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br/>
                    <br/>
                    <div className="advantages-video">
                        <VModal videoId={'2EgGTfa9VvM'} imgPreview={`${imageDir}/video1.webp`} />
                    </div>
                    <h2 id="advantages" className="text-white text-center text-img-header">ПЕРЕВАГИ:</h2>
                    <div className='full-width-items constructor-advantages'>
                        <div className="item-wrapper">
                            <div className="item">
                                <div className="item-img">
                                    <img src={`${imageDir}/icon1.png`} alt='icon1' />
                                </div>
                                <div className="item-desc">
                                    <p className="text-black">Прямі поставки від виробників:</p>
                                    <p className="text-white">"Рошен", "Каскад", "ПакДім".</p>
                                </div>
                            </div>
                            <div className="item">
                                <div className="item-img">
                                    <img src={`${imageDir}/icon2.png`} alt='icon2' />
                                </div>
                                <div className="item-desc">
                                    <p className="black-text">Збір подарунка - після замовлення.</p>
                                    <p className="text-white">Що гарантує свіжість цукерок, з сертифікатом якості</p>
                                </div>
                            </div>
                        </div>
                        <div className="item-wrapper">
                            <div className="item">
                                <div className="item-img">
                                    <img src={`${imageDir}/icon3.png`} alt='icon3' />
                                </div>
                                <div className="item-desc">
                                    <p className="black-text">Економія бюджету!</p>
                                    <p className="text-white">Наші подарункові набори на 20% дешевше аналогів. Оплата
                                        найбільш зручним способом.</p>
                                </div>
                            </div>
                            <div className="item">
                                <div className="item-img">
                                    <img src={`${imageDir}/icon4.png`} alt='icon4' />
                                </div>
                                <div className="item-desc">
                                    <p className="black-text">Безкоштовна доставка по місту</p>
                                    <p className="text-white">Немає необхідності переплачувати за доставку</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </section>

            <div className="phone-container">
                <div className='container-fluid background background-2' id="constructor-info">
                    <div className='constructor-info-wrapper'>
                        <div className="constructor-info">
                            <h2 className="text-center">Конструктор солодощів</h2>
                            <p>
                                Самостійно обираєте кількість і види цукерок.
                                Вага і вартість рахується автоматично.
                                Обираєте упаковку, або індивідуально з каталогу. <br/>
                                В розділі "Ваше замовлення" - корегуєте ціну.
                                Вказуєте кількість подарунків і відправляєте.
                            </p>
                        </div>
                        <VModal videoId={'7Bis6RXMt5c'} imgPreview={`${imageDir}/video2.webp`} />
                        {/*<svg height="100%" version="1.1" viewBox="0 0 68 48" width="100%">*/}
                        {/*    <path className="ytp-large-play-button-bg"*/}
                        {/*          d="M66.52,7.74c-0.78-2.93-2.49-5.41-5.42-6.19C55.79,.13,34,0,34,0S12.21,.13,6.9,1.55 C3.97,2.33,2.27,4.81,1.48,7.74C0.06,13.05,0,24,0,24s0.06,10.95,1.48,16.26c0.78,2.93,2.49,5.41,5.42,6.19 C12.21,47.87,34,48,34,48s21.79-0.13,27.1-1.55c2.93-0.78,4.64-3.26,5.42-6.19C67.94,34.95,68,24,68,24S67.94,13.05,66.52,7.74z"*/}
                        {/*          fill="#f00"></path>*/}
                        {/*    <path d="M 45,24 27,14 27,34" fill="#fff"></path>*/}
                        {/*</svg>*/}
                    </div>
                    <h2 className="text-center text-black block-text">Як зробити замовлення подарунків ?</h2>
                    <div className='full-width-items'>
                        <div className='visual'>
                            <div className='visual-number'>1</div>
                            <img src={`${imageDir}/visual_1.png`} alt="visuall" className="visual-img" />
                            <div className="visual-text">
                                <span className="text-primary">Обрати солодощі з розділів:</span>
                                <br />
                                <span className="black-text">шоколадні цукерки, карамель, шоколад, натиснути кнопку "Додати" стільки разів, скільки цукерок буде в наборі.</span>
                            </div>
                        </div>
                        <div className='visual'>
                            <div className='visual-number'>2</div>
                            <img src={`${imageDir}/visual_2.png`} alt="visual2" className="visual-img" />
                            <div className="visual-text">
                                <span className="text-primary">Обрати упаковку</span>
                                <br />
                                <span
                                    className="black-text">у відповідному розділі, або замовити персональний підбір.</span>
                            </div>
                        </div>
                        <div className='visual'>
                            <div className='visual-number'>3</div>
                            <img src={`${imageDir}/visual_3.png`} alt="visual3" className="visual-img" />
                            <div className="visual-text">
                                <span className="text-primary">Перейти у розділ "Ваше замовлення"</span>
                                <br />
                                <span className="black-text">і звірити склад набору. Зверху буде вказана вага і вартість подарунку, при необхідності можна внести корективи на цій же сторінці.</span>
                            </div>
                        </div>
                        <div className='visual'>
                            <div className='visual-number'>4</div>
                            <img src={`${imageDir}/visual_4.png`} alt="visua4" className="visual-img" />
                            <div className="visual-text">
                                <span className="text-primary">Внизу сторінки ввести необхідну кількість наборів</span>
                                <br />
                                <span className="black-text">звірити загальну суму та надіслати замовлення.</span>
                            </div>
                        </div>
                    </div>
                    <div className="text-center purchase-btn-wrapper">
                        <Link to='/purchase' className="btn btn-primary purchase-btn">
                            Скласти подарунок
                        </Link>
                    </div>
                </div>
                <div className='container-fluid background background-3' id="reasons">
                    <div className='container'>
                        <h2 className="text-center text-white header-text">Чому варто скористатись конструктором
                            новорічних подарунків:</h2>
                    </div>
                    <div className="constructor-features">
                        <div className='full-width-items'>
                            <div className="item-wrapper">
                                <div className="item-constructor">
                                    <div className="item-img">
                                        <img src={`${imageDir}/icon5.png`} alt='icon5' className="footer-img" />
                                    </div>
                                    <div className="item-desc">
                                        <p className="text-black">Оптова ціна</p>
                                        <p className="text-white">кожної цукерки з відповідною вагою, що дозволяє вибрати
                                            бажану комплектацію і кількість солодощів у подарунку.</p>
                                    </div>
                                </div>
                                <div className="item-constructor">
                                    <div className="item-img">
                                        <img src={`${imageDir}/icon6.png`} alt='icon6' className="footer-img" />
                                    </div>
                                    <div className="item-desc">
                                        <p className="black-text">Автоматичний підрахунок</p>
                                        <p className="text-white">При додаванні цукерок автоматично рахується вага і
                                            вартість набору.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="item-wrapper">
                                <div className="item-constructor">
                                    <div className="item-img">
                                        <img src={`${imageDir}/icon7.png`} alt='icon7' className="footer-img" />
                                    </div>
                                    <div className="item-desc">
                                        <p className="black-text">Напрочуд зручно і легко</p>
                                        <p className="text-white">В процесі формування набору цукерок можна корегувати
                                            кількість і вартість, забезпечивши виконання бюджетних можливостей.</p>
                                    </div>
                                </div>
                                <div className="item-constructor">
                                    <div className="item-img">
                                        <img src={`${imageDir}/icon8.png`} alt='icon8' className="footer-img" />
                                    </div>
                                    <div className="item-desc">
                                        <p className="black-text">Найкраща упаковка</p>
                                        <p className="text-white">Легко обрати красиву упаковку подарунку, яка сподобається
                                            дітям і дорослим, або замовити персональний підбір - додати логотип фірми і
                                            вітальну листівку.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='container'>
                        <div className="text-center error-btn">
                             <Link to="viber://chat/?number=%2B380675551308" className="btn btn-warning">
                                Повідомити про помилку
                             </Link>
                        </div>
                        <h2 className="text-center text-white header-text">Якщо при відправці сталася помилка</h2>
                        <p className="black-text text-center">зробіть скріншот замовлення та відправте нам</p>
                        <div className="footer-text">
                            <p className="text-white text-center no-margin">Viber/telegram
                                &nbsp;
                                <a className="text-white" href="tel:067-555-13-08">067-555-13-08 </a>
                            </p>
                            <p className="text-white no-margin text-center">podarkivinnica@gmail.com</p>
                        </div>
                    </div>
                </div>
                <div className="callback-container">
                    <a id="callback-btn" aria-label="Подзвонити" className="phone-circle" href="tel:+380675551308">
                        <img src={phone} alt="" className="phone"/>
                     </a>
                </div>
            </div>
        </React.Fragment>
    );
}

export default WelcomePage;
