import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createBrowserHistory } from "history";
import { initWebStats, webStatsSendPageView } from './utils/webStats';

import configureStore from './store';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

const initialState = window.__INITIAL_STATE__ || {};
const store = configureStore(initialState);
const history = createBrowserHistory();

initWebStats();

if (window.fbq) {
  setTimeout(() => {
    window.fbq('track', '30s');
  }, 30000);
};

history.listen(({ pathname }) => {
  webStatsSendPageView(pathname);
});

const WrapperApp = () => {
  return (
    <Provider store={store}>
      <App history={history} />
    </Provider>
  );
}

ReactDOM.render(<WrapperApp />, document.getElementById('root'));

serviceWorker.unregister();
