import candies from './ASSORTMENT';

export default {
    id: 'avtomobil-santy',
    name: '«Автомобіль Санти»',
    price: 87.04,
    weight: 303,
    image: 'giftBox/Avtomobil_santy-1.webp',
    analog: {
        name: '«Автомобіль Санти»',
        image: 'giftBox/0nash-avtomobil-santy.webp',
        price: 65,
    },
    content: [
        {
            ...candies.chervonyiMak,
            qantity: 2,
        },
        {
            ...candies.kokochoko,
            qantity: 2,
        },
        {
            ...candies.slivkiLenivki,
            qantity: 2,
        },
        {
            ...candies.candyNutArahis,
            qantity: 3,
        },
        {
            ...candies.romashka,
            qantity: 2,
        },
        {
            ...candies.milkySplash,
            qantity: 3,
        },
        {
            ...candies.toffelini,
            qantity: 2,
        },
        {
            ...candies.nuga,
            qantity: 2,
        },
        {
            ...candies.galeretkiApelsin,
            qantity: 4,
        },
        {
            ...candies.sunnyZhuk,
            qantity: 3,
        },
        {
            ...candies.lolipopCocktail,
            qantity: 2,
        }
    ],
};
