import * as constants from '../constants';

const DEFAULT_STATE = {
  purchase: [],
  gift_count: 0
};

export default (state = DEFAULT_STATE, action) => {
  switch(action.type) {
    case constants.ADD_TO_BASKET: {
      const { purchase } = state;
      const { item } = action;
      const isInBusket = purchase.find(purchaseItem => purchaseItem.id === item.id);
      let nextPurchase = [ ...purchase ];

      if (isInBusket) {
        const nextItem = { ...item, count: isInBusket.count + 1 };

        nextPurchase = purchase.map(purchaseItem => purchaseItem.id === item.id ? { ...item, ...nextItem } : purchaseItem);
      } else nextPurchase.push({ ...item, count: item.count + 1 });

      return {
        ...state,
        purchase: nextPurchase
      };
    }
    case constants.DELETE_FROM_BASKET: {
      const { purchase } = state;
      const { item } = action;
      const isInBusket = purchase.length > 0 ? purchase.find(purchaseItem => purchaseItem.id === item.id) : false;

      if (!isInBusket) {
        return { ...state };
      }
      let nextPurchase = [ ...purchase ];
      const itemCount = isInBusket.count - 1;

      if (itemCount < 1) nextPurchase = nextPurchase.filter(purchaseItem => purchaseItem.id !== item.id);
      else nextPurchase = nextPurchase.map(purchaseItem => purchaseItem.id === item.id ? { ...item, count: purchaseItem.count -1 } : purchaseItem);

      return {
        ...state,
        purchase: nextPurchase
      }
    }
    case constants.GIFT_COUNT_INCREMENT: {
      return {
        ...state,
        gift_count: action.count
      }
    }
    case constants.GIFT_COUNT_DECREMENT: {
      const { gift_count } = state;

      return {
        ...state,
        gift_count: gift_count > 0 ? gift_count - 1 : 0
      }
    }
    default: return state;
  }
}
