import candies from './ASSORTMENT';

export default {
    id: 'novorichna-yalynka',
    name: '«Новорічна ялинка»',
    price: 102,
    weight: 387,
    image: 'giftBox/ELKA_2023.webp',
    analog: {
        name: '«Новорічна ялинка»',
        image: 'giftBox/0nash-ny-tree.webp',
        price: 96,
    },
    content: [
        {
            ...candies.chervonyiMak,
            qantity: 2,
        },
        {
            ...candies.slivkiLenivki,
            qantity: 1,
        },
        {
            ...candies.candyNutArahis,
            qantity: 3,
        },
        {
            ...candies.romashka,
            qantity: 3,
        },
        {
            ...candies.milkySplash,
            qantity: 3,
        },
        {
            ...candies.toffelini,
            qantity: 2,
        },
        {
            ...candies.sorrento,
            qantity: 3,
        },
        {
            ...candies.nuga,
            qantity: 3,
        },
        {
            ...candies.galeretkiApelsin,
            qantity: 3,
        },
        {
            ...candies.sunnyZhuk,
            qantity: 4,
        },
        {
            ...candies.lolipopCocktail,
            qantity: 3,
        },
        {
            ...candies.didMoroz,
            qantity: 1,
        }
    ],
};
