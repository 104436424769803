import goods from "../giftBox/ASSORTMENT";
export default [
  {
    ...goods.korivka,
    id:'chocolate_11',
    count: 0
  },
  {
    ...goods.fangenta,
    id:'caramel_16',
    count: 0
  },
  {
    ...goods.stakanMoloka,
    id:'caramel_2',
    count: 0
  },
  {
    ...goods.toffelini,
    id:'caramel_9',
    count: 0
  },
  {
    ...goods.minkibinki,
    id:'caramel_7',
    count: 0
  },
  {
    ...goods.ekler,
    id:'caramel_0',
    count: 0
  },
  {
    ...goods.bdgilka,
    id:'caramel_5',
    count: 0
  },
  {
    ...goods.gely,
    id:'caramel_12',
    count: 0
  },
  {
    ...goods.jamis,
    id:'caramel_11',
    count: 0
  },
  {
    ...goods.rachki,
    id:'caramel_1',
    count: 0
  },
  {
    ...goods.milkKraplia,
    id:'caramel_14',
    count: 0
  },
  {
    ...goods.coffeelike,
    id:'caramel_4',
    count: 0
  },
  {
    ...goods.jogurtni,
    id:'caramel_10',
    count: 0
  },
  {
    ...goods.kislinka,
    id:'caramel_6',
    count: 0
  },
  {
    ...goods.lolipopGum,
    id:'caramel_13',
    count: 0
  },
  {
    ...goods.lolipopYogurt,
    id:'caramel_15',
    count: 0
  }
]
