import candies from './ASSORTMENT';

export default {
    id: 'pes-patron',
    name: '«Пес Патрон»',
    price: 198,
    weight: 627,
    image: 'giftBox/budynokPatron.webp',
    analog: {
        name: '«Пес Патрон»',
        image: 'giftBox/budynokPatron.webp',
        price: 173,
    },
    content: [
        {
            ...candies.monblan,
            qantity: 2,
        },
        {
            ...candies.kokochoko,
            qantity: 2,
        },
        {
            ...candies.chervonyiMak,
            qantity: 2,
        },
        {
            ...candies.karakum,
            qantity: 2,
        },
        {
            ...candies.romashka,
            qantity: 3,
        },
        {
            ...candies.kanafeto,
            qantity: 3,
        },
        {
            ...candies.slivkiLenivki,
            qantity: 1,
        },
        {
            ...candies.candyNut,
            qantity: 1,
        },
        {
            ...candies.candyNut2,
            qantity: 2,
        },
        {
            ...candies.nuga,
            qantity: 2,
        },
        {
            ...candies.croc,
            qantity: 2,
        },
        {
            ...candies.stakanMoloka,
            qantity: 3,
        },
        {
            ...candies.bdgilka,
            qantity: 3,
        },
        {
            ...candies.gely,
            qantity: 3,
        },
        {
            ...candies.kislinka,
            qantity: 3,
        },
        {
            ...candies.chokoBatonchyk,
            qantity: 1,
        },
        {
            ...candies.lacmiRed,
            qantity: 1,
        },
    ],
};
