import goods from "../giftBox/ASSORTMENT";

export default [
  //  400
  goods.podarunokMykolayaPaket,
  goods.ridnyiDim,
  goods.podarunokZaychuka,
  goods.podarunokSanty,
  goods.solodkaHatynka,
  // goods.symbolNY2022,
  goods.sunduchok400,
  goods.lihtarWrapper,
  //  650
  goods.nyPakunok,
  goods.saintNic,
  goods.lihtarykWrapper,
  goods.sunduchok600,
  goods.novorichnyiBant,
  goods.ranetsGnom,
  goods.budynokPatron,
  //  800
  goods.nyPost,
  goods.santaHouseWrapper,
  goods.saintNic800,
  goods.sunduchok800,
  goods.budynokPatron800,
  goods.veselyiSnigovyk,
  goods.shchaslyvyhSviat,
  // 1000
  goods.zelenaSkrynia,
  goods.novorichnyiHodynnyk,
  goods.nyBellWrapper,
  goods.portfelSantyWrapper,
  goods.saintNic1000,
  goods.veselyhSvyat1k,
  goods.vehzaDrakon,
].map((w,i) => ({...w, count: 0, id: `wrapper_${i + 1}`}));
