import React, { useState } from "react"
import giftBoxes from '../../assets/giftBox';
import OrderModal from '../../containers/OrderModalMainScreen';
import { Redirect } from "react-router-dom";
import './index.css';

export const GiftBoxDetail = ({ match }) => {
    const [box] = useState(giftBoxes.find(item => item.id === match.params.id));
    const [selectedBox, setBox] = useState(box);
    const [showModal, setShowModal] = useState(false);
    if (!box) {
        return <Redirect to={'/'}/>
    }

    const handleOpenModal = (box) => {
        setShowModal(true);
        setBox(box);
    };
    const handleCloseModal = () => {
        setShowModal(false);
    };

    return (
        <section className="hero">
            <div className="gbox-wrapper">
                <div className="giftbox__compare">
                    <div className="gbox original-giftbox">
                        <h1 className="giftbox__name">{box.name} - {box.weight} г</h1>
                        <img alt={box.name} className="giftbox__img" src={`/images/${box.image}`}/>
                    </div>
                </div>
                <div className="candy-list-wrapper">
                    <h2>Склад подарунку: </h2>
                    <ol className="box__candy-list">
                        {box.content.map((item, index) => (
                            <li className="box__candy-item" key={item.name}>
                                <div>
                                    {index + 1}
                                </div>
                                <div>
                                    <img alt={item.name} src={`/images/${item.image || item.img_name}`}
                                         className="box__candy"/>
                                </div>
                                <div className="box__candy-name">
                                    {item.name}
                                </div>
                                <div className="box__candy-quantity">
                                    {`${item.qantity} шт`}
                                </div>
                            </li>
                        ))}

                        <li className="box__candy-item quantity">
                            <div>Всього цукерок:</div>
                            <div className="">{box.content.reduce((acc, i) => acc + i.qantity, 0)} шт</div>
                        </li>
                        <li className="box__candy-item price">
                            <div>Ціна:</div>
                            <div className="">{box.price} грн</div>
                        </li>
                    </ol>
                    <div className="cta-wrapper">
                        <button
                            type="button" className="btn yellow-bg"
                            onClick={() => handleOpenModal(box)}
                        >
                            Замовити
                        </button>
                    </div>
                </div>
                {box.analog && (<div className="analog-wrapper">
                    <div className="analog-giftbox">
                        <div className="analog">
                            <h2 className="giftbox__name">
                                Бажаєте придбати подарунок
                                <br/>
                                на
                                <span className="price__difference"> {(box.price - box.analog.price).toFixed(0)}</span> грн дешевше?
                                <br/>
                                Замовте в нашій упаковці
                            </h2>
                            <img alt={box.analog.name} className="giftbox__img" src={`/images/${box.analog.image}`}/>
                            {box.analog.name} - {box.weight} г
                            (цукерки ті ж самі)
                        </div>
                        <div className="analog-price">
                            <span className="price__old">{Math.floor(box.price)}.
                                <span
                                    className="decimals">{(box.price % 1 * 100) ? (box.price % 1 * 100).toFixed(0) : '00'}</span>
                            </span>
                            <span className="price__new">
                                {Math.floor(box.analog.price)}.
                                <span
                                    className="decimals">{(box.analog.price % 1 * 100) ? (box.analog.price % 1 * 100).toFixed(0) : '00'} грн</span>
                            </span>
                        </div>
                    </div>

                    <div className="cta-wrapper">
                        <button
                            type="button" className="btn yellow-bg"
                            onClick={() => handleOpenModal({...box, ...box.analog})}
                        >
                            Замовити
                        </button>
                    </div>
                </div>)}
            </div>


            {showModal && <OrderModal onCloseModal={handleCloseModal} selectedBox={selectedBox}/>}
        </section>
    )
};
