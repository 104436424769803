import candies from './ASSORTMENT';

export default {
    id: 'schaslyvyh-svyat',
    name: '«Щасливих свят»',
    price: 300,
    weight: 876,
    image: 'giftBox/schaslyvyh-svyat.webp',
    analog: {
        name: '«Щасливих свят»',
        price: 240,
        image: 'giftBox/schaslyvyh-svyat.webp',
    },
    content: [
        {
            ...candies.monblan,
            qantity: 3,
        },
        {
            ...candies.kokochoko,
            qantity: 3,
        },
        {
            ...candies.granola,
            qantity: 3,
        },
        {
            ...candies.ptashyneMoloko,
            qantity: 3,
        },
        {
            ...candies.lishina,
            qantity: 2,
        },
        {
            ...candies.chervonyiMak,
            qantity: 3,
        },
        {
            ...candies.karakum,
            qantity: 2,
        },
        {
            ...candies.romashka,
            qantity: 3,
        },
        {
            ...candies.kanafeto,
            qantity: 4,
        },
        {
            ...candies.slivkiLenivki,
            qantity: 1,
        },
        {
            ...candies.shokolapki,
            qantity: 4,
        },
        {
            ...candies.candyNut,
            qantity: 1,
        },
        {
            ...candies.candyNut2,
            qantity: 3,
        },
        {
            ...candies.croc,
            qantity: 3,
        },
        {
            ...candies.fangenta,
            qantity: 4,
        },
        {
            ...candies.stakanMoloka,
            qantity: 4,
        },
        {
            ...candies.toffelini,
            qantity: 4,
        },
        {
            ...candies.gely,
            qantity: 4,
        },
        {
            ...candies.jogurtni,
            qantity: 4,
        },
        {
            ...candies.chokoBatonchyk,
            qantity: 1,
        },
        {
            ...candies.extraDark,
            qantity: 1,
        },
    ],
};
