import candies from './ASSORTMENT';

export default {
    id: 'symvol_novogo_roku_2024',
    name: '«Символ Нового року»',
    price: 200,
    weight: 562,
    image: 'giftBox/symbolNY2024.webp',
    analog: {
        name: '«Символ Нового року»',
        image: 'giftBox/symbolNY2024.webp',
        price: 154,
    },
    content: [
        {
            ...candies.bubbleCrisp,
            qantity: 1,
        },
        {
            ...candies.monblan,
            qantity: 2,
        },
        {
            ...candies.kokochoko,
            qantity: 2,
        },
        {
            ...candies.lishina,
            qantity: 2,
        },
        {
            ...candies.chervonyiMak,
            qantity: 3,
        },
        {
            ...candies.romashka,
            qantity: 3,
        },
        {
            ...candies.kanafeto,
            qantity: 3,
        },
        {
            ...candies.slivkiLenivki,
            qantity: 1,
        },
        {
            ...candies.shokolapki,
            qantity: 3,
        },
        {
            ...candies.candyNut,
            qantity: 1,
        },
        {
            ...candies.nuga,
            qantity: 3,
        },
        {
            ...candies.croc,
            qantity: 3,
        },
        {
            ...candies.stakanMoloka,
            qantity: 3,
        },
        {
            ...candies.bdgilka,
            qantity: 3,
        },
        {
            ...candies.jogurtni,
            qantity: 3,
        }
    ],
};
