import * as constants from '../constants';
import axios from 'axios';

export function sendLeadForm(userData) {
  return axios.post('/send-lead', { userData })
      .then(data => {

        return data;
      }).catch(err => {
        console.log('err', err);

        throw err;
      });
}

export function sendGiftBoxLead(userData, purchase) {
  console.log('post');
  return axios.post('/send-lead-giftbox', { userData, purchase })
    .then(data => {
      console.log('data', data);

      return data;
    }).catch(err => {
      console.log('err', err);

      throw err;
    });
}
export function sendPurcahse(userData, purchase) {
  console.log('post');
  return axios.post('/send-purcahse', { userData, purchase })
    .then(data => {
      console.log('data', data);

      return data;
    }).catch(err => {
      console.log('err', err);

      throw err;
    });
}

export function addToBusket(item) {
  return dispatch => {
    dispatch({ type: constants.ADD_TO_BASKET, item });
  };
}

export function deleteFromBusket(item) {
  return dispatch => {
    dispatch({ type: constants.DELETE_FROM_BASKET, item });
  };
}

export function incrementGift(count) {
  return dispatch => {
    dispatch({ type: constants.GIFT_COUNT_INCREMENT, count: count });
  };
}

export function decrementGift() {
  return dispatch => {
    dispatch({ type: constants.GIFT_COUNT_DECREMENT });
  };
}
