import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { sendwebStatsLeadEvent } from '../../utils/webStats';

import { sendPurcahse } from '../../actions';

class OrderModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      body: {
        name: '',
        surname: '',
        phone: '',
        email: ''
      },
      errors: {},
      isReq: false,
      opeartionStatus: 0
    };
  }

  handleOnChange = e => {
    const { body, errors } = this.state;
    const { name, value } = e.target;

    delete errors[name];
    body[name] = value;

    this.setState({ body: { ...body }, errors });
  };

  handleSubmit = e => {
    e.preventDefault();
    const { basket } = this.props;
    const { body } = this.state;
    const { isValid, errors } = this.validateForm(body);

    if (!isValid) return this.setState({ errors });
    this.setState({ isReq: true, opeartionStatus: 0 });
    sendPurcahse(body, basket)
      .then(() => {
        this.setState({ isReq: false, opeartionStatus: 1 });
        sendwebStatsLeadEvent({ event: 'Lead' });
      }).catch(() => {
        this.setState({ isReq: false, opeartionStatus: 2 });
      });
  }

  validateForm = body => {
    let isValid = true;
    const errors = {};

    Object.keys(body).forEach(field => {
      if (!body[field]) {
        errors[field] = "Поле обов'язкове";
        isValid = false;
      }
    });

    return { isValid, errors };
  };

  render() {
    const { isReq, opeartionStatus, body, errors } = this.state;
    const { onCloseModal } = this.props;

    return (
      <div className="modal custom-modal" id="exampleModal" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
            {opeartionStatus !== 1 && <h5 className="modal-title" id="exampleModalLabel">Напишіть Ваше</h5>}
              <button type="button" className="close" onClick={onCloseModal} data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              {
                (opeartionStatus === 0 || opeartionStatus === 2) &&
                <form onSubmit={this.handleSubmit} id="order-form">
                  <div className="form-group">
                    <label htmlFor="name" className="col-form-label">Ім'я:</label>
                    <input type="text" value={body.name} required className={`form-control ${errors.name ? 'is-invalid' : ''}`} id="name" name="name" onChange={this.handleOnChange} />
                    {
                      errors.name &&
                      <div className="invalid-feedback">
                        {errors.name}
                      </div>
                    }
                  </div>
                  <div className="form-group">
                    <label htmlFor="surname" className="col-form-label">Прізвище:</label>
                    <input type="text" value={body.surname} required className={`form-control ${errors.surname ? 'is-invalid' : ''}`} id="surname" name="surname" onChange={this.handleOnChange} />
                    {
                      errors.surname &&
                      <div className="invalid-feedback">
                        {errors.surname}
                      </div>
                    }
                  </div>
                  <div className="form-group">
                    <label htmlFor="phone" className="col-form-label">Телефон:</label>
                    <input type="text" required value={body.phone} className={`form-control ${errors.phone ? 'is-invalid' : ''}`} id="phone" name="phone" onChange={this.handleOnChange} />
                    {
                        errors.phone &&
                        <div className="invalid-feedback">
                          {errors.phone}
                        </div>
                    }
                  </div>
                  <div className="form-group">
                    <label htmlFor="email" className="col-form-label">Email:</label>
                    <input type="email" required value={body.email} className={`form-control ${errors.email ? 'is-invalid' : ''}`} id="email" name="email" onChange={this.handleOnChange} />
                    {
                      errors.email &&
                      <div className="invalid-feedback">
                        {errors.email}
                      </div>
                    }
                  </div>
                </form>
              }
              {
                opeartionStatus === 1 &&
                <div className="alert alert-success">
                  Дякуємо за замовлення! Менеджер зателефонує Вам протягом 30 хвилин.
                </div>
              }
              {
                opeartionStatus === 2 &&
                <div className="alert alert-danger">
                  Сталася помилка. Спробуйте пізніше.
                </div>
              }
            </div>
            <div className="modal-footer">
              <button type="button" onClick={onCloseModal} className="btn btn-secondary" data-dismiss="modal">
                {
                  opeartionStatus === 1 ?
                   'Закрити'
                   :
                   'Відмінити'
                }
              </button>
              {
                (opeartionStatus === 0 || opeartionStatus === 2) &&
                <button type="submit" onClick={this.handleSubmit} disabled={isReq} className="btn btn-primary">Надіслати</button>
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

OrderModal.propTypes = {
  onCloseModal: PropTypes.func.isRequired,
  basket: PropTypes.object.isRequired
};

const mapStateToProps = ({ basket }) => ({ basket });
const withConnect = connect(mapStateToProps);

export default compose(withConnect)(OrderModal);
