import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';

import gifts from '../../assets/gifts';
import Wrapper from '../WrapperRow';

const wrapperManager = {
    id: 'wrapper_manager',
    name: 'Персональний підбір',
    img_name: 'gifts/questionwrapper.webp',
    count: 0,
    mock: true
};

class WrapperBoard extends Component {
    render() {
        const { giftGroupName } = this.props;
        const currentGifts = gifts[giftGroupName];
        const { basket } = this.props;
        let currentPurchaseWeight = 0;
        const isBasketEmpty = basket.purchase && basket.purchase.length === 0;
        let minWeight;
        let maxWeight;
        let questionPrice;
        let questionContain;

        if (!isBasketEmpty) {
            basket.purchase && basket.purchase.forEach(element => {
                currentPurchaseWeight += (element.weight || 0) * element.count;
            });
        }
        if (currentPurchaseWeight <= 400) {
            minWeight = 0;
            maxWeight = 400;
            questionPrice = 9.5;
            questionContain = 400;
        }
        if (currentPurchaseWeight > 400 && currentPurchaseWeight <= 650) {
            minWeight = 401;
            maxWeight = 650;
            questionPrice = 15;
            questionContain = 600;
        }
        if (currentPurchaseWeight > 650 && currentPurchaseWeight <= 800) {
            minWeight = 651;
            maxWeight = 800;
            questionPrice = 15;
            questionContain = 800;
        }
        if (currentPurchaseWeight > 800) {
            minWeight = 801;
            maxWeight = 10000;
            questionPrice = 15;
            questionContain = 1000;
        }

        return (
            <div className="row gift-board">
                {
                    currentGifts && currentGifts.map((gift) => {

                        return (
                            (gift.contain >= minWeight && gift.contain <= maxWeight)
                                && <Wrapper gift={gift} key={gift.name} />
                        );
                    })
                }
                <Wrapper gift={{...wrapperManager, price: questionPrice, contain: questionContain }} />
            </div>
        );
    }
}

WrapperBoard.propTypes = {
    giftGroupName: PropTypes.string.isRequired,
    basket: PropTypes.object.isRequired
};

const mapStateToProps = ({ basket }) => ({ basket });
const withConnect = connect(mapStateToProps);

export default compose(withConnect)(WrapperBoard);
