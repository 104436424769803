import goods from "../giftBox/ASSORTMENT"
export default [
  {
    ...goods.monblan,
    id:'chocolate_0',
    count: 0
  },
  {
    ...goods.kokochoko,
    id:'chocolate_5',
    count: 0
  },
  {
    ...goods.granola,
    id:'chocolate_2',
    count: 0
  },
  {
    ...goods.ptashyneMoloko,
    id:'chocolate_12f',
    count: 0
  },
  {
    ...goods.lishina,
    id:'chocolate_15',
    count: 0
  },
  {
    ...goods.chervonyiMak,
    id:'chocolate_12',
    count: 0
  },
  {
    ...goods.karakum,
    id:'karakum',
    count: 0
  },
  {
    ...goods.romashka,
    id:'chocolate_1',
    count: 0
  },
  {
    ...goods.kanafeto,
    id:'chocolate_6',
    count: 0
  },
  {
    ...goods.slivkiLenivki,
    id:'chocolate_16',
    count: 0
  },
  {
    ...goods.jonhykroker,
    id:'chocolate_14',
    count: 0
  },
  {
    ...goods.shokolapki,
    id:'chocolate_7',
    count: 0
  },
  {
    ...goods.candyNut,
    id:'chocolate_9',
    count: 0
  },
  {
    ...goods.candyNut2,
    id:'chocolate_13',
    count: 0
  },
  {
    ...goods.sorrento14g,
    id:'chocolate_10',
    count: 0
  },
  {
    ...goods.croc,
    id:'chocolate_4',
    count: 0
  },
  // {
  //   ...goods.sorrento,
  //   id:'chocolate_4',
  //   count: 0
  // }
]
