import goods from "../giftBox/ASSORTMENT";

export default [
  {
    ...goods.chokoBatonchyk,
    id:'bigchocolate_6',
    count: 0
  },
  {
    ...goods.tidbit,
    id:'bigchocolate_3',
    count: 0
  },
  {
    ...goods.darkAir,
    id:'bigchocolate_1',
    count: 0
  },
  {
    ...goods.milkPeanut,
    id: 'bigchocolate_2',
    count: 0
  },
  {
    ...goods.lacmiBlue,
    id:'bigchocolate_4',
    count: 0
  },
  {
    ...goods.lacmiRed,
    id:'bigchocolate_5',
    count: 0
  },
  {
    ...goods.lacmiGreen,
    id:'bigchocolate_7',
    count: 0
  },
  {
    ...goods.extraDark,
    id:'bigchocolate_8',
    count: 0
  }
]
