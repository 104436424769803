export const initWebStats = () => {
    // const MEASUREMENT_ID = 'G-42K53G55W1';
    // const MEASUREMENT_ID2 = 'GTM-WPQGS2R';
    // const FB_PIXEL_ID = 365414087680707; an old fb pixel
    const FB_PIXEL_ID = 1353529928170350;


    // const analiticsSrcScirpt = document.createElement('script');
    //
    // analiticsSrcScirpt.src = `https://www.googletagmanager.com/gtag/js?id=${MEASUREMENT_ID}`;
    // analiticsSrcScirpt.async = true;
    // document.head.appendChild(analiticsSrcScirpt);
    //
    // const analiticsScirpt = document.createElement('script');
    //
    // analiticsScirpt.innerHTML = `
    //     window.dataLayer = window.dataLayer || [];
    //     function gtag(){dataLayer.push(arguments);}
    //     gtag('js', new Date());
    //     gtag("config", "${MEASUREMENT_ID}")
    // `;
    //
    // document.head.appendChild(analiticsScirpt);
    //
    //
    // const gtmSrcScirpt = document.createElement('script');
    //
    // gtmSrcScirpt.src = `https://www.googletagmanager.com/gtag/js?id=${MEASUREMENT_ID2}`;
    // gtmSrcScirpt.async = true;
    // document.head.appendChild(gtmSrcScirpt);
    //
    // const gtmScirpt = document.createElement('script');
    //
    // gtmScirpt.innerHTML = `
    //     window.dataLayer = window.dataLayer || [];
    //     function gtag(){dataLayer.push(arguments);}
    //     gtag('js', new Date());
    //     gtag("config", "${MEASUREMENT_ID2}")
    // `;
    //
    // document.head.appendChild(gtmScirpt);

    const fbPixel = document.createElement('script');

    fbPixel.async = true;
    fbPixel.innerHTML = `
        !function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)}(window, document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', '${FB_PIXEL_ID}');
        fbq('track', 'PageView');
    `;

    document.head.appendChild(fbPixel);

};

export const webStatsSendPageView = (pathname) => {
    if (window.gtag) {
        window.gtag('send', 'pageview', {
            page: pathname,
        });
    }

    if (window.fbq) {
        window.fbq('track', 'PageView', { page: pathname });
    }
};

export const sendwebStatsLeadEvent = (params) => {
    if (window.fbq) {
        window.fbq('track', params.event, params.paylaod);
    }

    if (window.gtag) {
        window.gtag('event', 'purcahse');
    }
};
