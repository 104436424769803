import React, { Component } from 'react';
import PropTypes from 'prop-types';

import gifts from '../../assets/gifts';
import Gift from '../GiftRow';

class GiftsBoard extends Component {
    render() {
        const { giftGroupName } = this.props;
        const currentGifts = gifts[giftGroupName];

        return (
            <div className="row gift-board">
                {
                    currentGifts && currentGifts.map((gift, index) => {
                        return (
                            <Gift gift={gift} key={index} />
                        );
                    })
                }
            </div>
        );
    }
}

GiftsBoard.propTypes = {
    giftGroupName: PropTypes.string.isRequired
};

export default GiftsBoard;
