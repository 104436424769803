import React, {useState, useRef} from 'react';

const VModal = ({videoId, imgPreview}) => {
	const [show, setShow] = useState(false)
	const ref = useRef(null);

	return show
		? (
			<div className="constructor-video">
				<iframe
					width={ref.current?.width} height={ref.current?.height} src={`https://www.youtube.com/embed/${videoId}`}
					title="YouTube video player" frameBorder="0"
					allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
					allowFullScreen
				/>
			</div>
		) : (
			<div className="constructor-video" onClick={()=> setShow(true)}>
				<div className="previewImage">
					<img
						ref={ref}
						src={imgPreview || `https://i.ytimg.com/vi/${videoId}/hq720.jpg`}
						alt=""
					/>
				</div>
			</div>
		)
}

export default VModal;
