import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose, bindActionCreators } from 'redux';

import { addToBusket, deleteFromBusket } from '../../actions';

class Gift extends Component {
  handleAddToBusketItem = () => {
    const { gift } = this.props;

    this.props.addToBusket(gift);
  };

  handleDeleteFromBusketItem = () => {
    const { gift } = this.props;

    this.props.deleteFromBusket(gift);
  };

  render() {
    const { gift, basket } = this.props;
    const isGiftInusket = basket.purchase && basket.purchase.find(item => item.id === gift.id);

    return (
      <div className="col-12 col-md-4 col-lg-3 gift">
        <div className="card">
          <div className="img-gift-wrapper">
            <img src={`${process.env.PUBLIC_URL}/images/${gift.img_name}`} className="card-img-top wrapper-img" alt="..." />
          </div>
          <div className="card-body">
            <p className="font-weight-bold card-title">{gift.name}</p>
            {
              gift.contain &&
              <React.Fragment>
                <p className="card-text">
                  Ціна: {gift.price} грн
                  &nbsp;
                  Вміщує: {gift.contain} г
                </p>
                <div className="input-group mb-3">
                  <div className="input-group-prepend">
                    <button className="btn btn-outline-secondary input-group-btn" onClick={this.handleAddToBusketItem} type="button">Додати</button>
                    <button className="btn btn-outline-secondary input-group-btn" onClick={this.handleDeleteFromBusketItem} type="button">Видалити</button>
                  </div>
                  <input type="text" className="form-control text-center" disabled value={(isGiftInusket && isGiftInusket.count) || 0} aria-describedby="button-addon3" />
                </div>
              </React.Fragment>
            }
          </div>
        </div>
      </div>
    );
  }
}

Gift.propTypes = {
  gift: PropTypes.object.isRequired,
  basket: PropTypes.object.isRequired,
  addToBusket: PropTypes.func.isRequired,
  deleteFromBusket: PropTypes.func.isRequired
};

const mapStateToProps = ({ basket }) => ({ basket });
const mapDispatchToProps = dispatch => bindActionCreators({ addToBusket, deleteFromBusket }, dispatch);
const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(Gift);
