import candies from './ASSORTMENT';

export default {
    id: 'novorichnyi-vinok',
    name: '«Новорічний вінок»',
    price: 118.79,
    weight: 425,
    image: 'giftBox/ny_vinok.webp',
    analog: {
        name: '«Новорічний вінок»',
        image: 'giftBox/sunduchok600.webp',
        price: 100,
    },
    content: [
        {
            ...candies.chervonyiMak,
            qantity: 2,
        },
        {
            ...candies.kokochoko,
            qantity: 2,
        },
        {
            ...candies.slivkiLenivki,
            qantity: 2,
        },
        {
            ...candies.candyNutArahis,
            qantity: 3,
        },
        {
            ...candies.romashka,
            qantity: 3,
        },
        {
            ...candies.milkySplash,
            qantity: 3,
        },
        {
            ...candies.karakum,
            qantity: 2,
        },
        {
            ...candies.nuga,
            qantity: 4,
        },
        {
            ...candies.galeretkiApelsin,
            qantity: 4,
        },
        {
            ...candies.sunnyZhuk,
            qantity: 4,
        },
        {
            ...candies.lolipopYogurt,
            qantity: 2,
        },
        {
            ...candies.chokoBatonCaramel,
            qantity: 1,
        }
    ],
};
