import candies from './ASSORTMENT';

export default {
    id: 'charivna-tsukerka',
    name: '«Чарівна цукерка»',
    price: 94.79,
    weight: 335,
    image: 'giftBox/charivna_tsukerka.webp',
    analog: {
        name: '«Чарівна цукерка»',
        image: 'giftBox/0nash-magic-candy.webp',
        price: 75,
    },
    content: [
        {
            ...candies.chervonyiMak,
            qantity: 2,
        },
        {
            ...candies.slivkiLenivki,
            qantity: 2,
        },
        {
            ...candies.candyNutArahis,
            qantity: 3,
        },
        {
            ...candies.romashka,
            qantity: 3,
        },
        {
            ...candies.milkySplash,
            qantity: 4,
        },
        {
            ...candies.toffelini,
            qantity: 2,
        },
        {
            ...candies.nuga,
            qantity: 3,
        },
        {
            ...candies.galeretkiApelsin,
            qantity: 2,
        },
        {
            ...candies.sunnyZhuk,
            qantity: 4,
        },
        {
            ...candies.lolipopCocktail,
            qantity: 2,
        }
    ],
};
