import candies from './ASSORTMENT';

export default {
    id: 'rizdvyanyi-yarmarok',
    name: '«Різдвяний ярмарок»',
    price: 142.79,
    weight: 470,
    image: 'giftBox/YARMARKA_2020.webp',
    analog: {
        name: '«Різдвяний ярмарок»',
        image: 'giftBox/javecat.webp',
        price: 124,
    },
    content: [
        {
            ...candies.chervonyiMak,
            qantity: 3,
        },
        {
            ...candies.karakum,
            qantity: 2,
        },
        {
            ...candies.toffelini,
            qantity: 3,
        },
        {
            ...candies.slivkiLenivki,
            qantity: 2,
        },
        {
            ...candies.candyNutArahis,
            qantity: 3,
        },
        {
            ...candies.romashka,
            qantity: 3,
        },
        {
            ...candies.milkySplash,
            qantity: 4,
        },
        {
            ...candies.sorrento,
            qantity: 3,
        },
        {
            ...candies.nuga,
            qantity: 3,
        },
        {
            ...candies.galeretkiApelsin,
            qantity: 3,
        },
        {
            ...candies.sunnyZhuk,
            qantity: 4,
        },
        {
            ...candies.lolipopYogurt,
            qantity: 3,
        },
        {
            ...candies.didMoroz,
            qantity: 1,
        },
    ],
};
