import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose, bindActionCreators } from 'redux';

import { addToBusket, deleteFromBusket } from '../../actions';

class BasketRow extends Component {
  handleAddToBusketItem = () => {
    const { gift } = this.props;

    this.props.addToBusket(gift);
  };

  handleDeleteFromBusketItem = () => {
    const { gift } = this.props;

    this.props.deleteFromBusket(gift);
  };

  render() {
    const { gift } = this.props;

    return (
      <tr>
        <td className="text-center">{gift.name}</td>
        <td className="table-img-wrapper">
        <img src={`${process.env.PUBLIC_URL}/images/${gift.img_name || gift.image}`} className="table-img" alt="gift" />
        </td>
        <td className="text-center">{(gift.price * gift.count).toFixed(2)} грн</td>
        <td className="text-center">{gift.count}</td>
        <td className="text-center">{(gift.weight || gift.contain) * gift.count} г</td>
        <td className="action-block">
          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <button className="btn btn-outline-secondary input-group-btn" onClick={this.handleAddToBusketItem} type="button">Додати</button>
              <button className="btn btn-outline-secondary input-group-btn" onClick={this.handleDeleteFromBusketItem} type="button">Видалити</button>
            </div>
          </div>
        </td>
      </tr>
    )
  }
}

BasketRow.propTypes = {
  gift: PropTypes.object.isRequired,
  addToBusket: PropTypes.func.isRequired,
  deleteFromBusket: PropTypes.func.isRequired
};

const mapDispatchToProps = dispatch => bindActionCreators({ addToBusket, deleteFromBusket }, dispatch);
const withConnect = connect(null, mapDispatchToProps);

export default compose(withConnect)(BasketRow);
