import sladkii_domik from "./sladkii_domik"
import novogodnyaya_sumochka from "./novogodnyaya_sumochka"
import avtomobil_santy from './avtomobil_santy';
import charivna_tsukerka from './charivna_tsukerka';
import novorichna_yalunka from './novorichna_yalunka';
import vesele_svyato from './vesele_svyato';
import ny_vinok from './ny_vinok';
import yarmarok from './yarmarok';
import novogodniy_kamin from './novogodniy_kamin';
import godivnytsa from './godivnytsa';
import symvol_novogo_roku_2024 from './symvol_novogo_roku_2024';
import pesPatron from './pesPatron';
import schaslyvyhSvyat from './schaslyvyhSvyat';


export default [
    sladkii_domik,
    novogodnyaya_sumochka,
    avtomobil_santy,
    charivna_tsukerka,
    vesele_svyato,
    novorichna_yalunka,
    ny_vinok,
    yarmarok,
    symvol_novogo_roku_2024,
    novogodniy_kamin,
    pesPatron,
    godivnytsa,
    schaslyvyhSvyat,
];
