import candies from './ASSORTMENT';

export default {
    id: 'vesele-svyato',
    name: '«Веселе свято»',
    price: 103.21,
    weight: 386,
    image: 'giftBox/vesele-svyato.webp',
    analog: {
        name: '«Веселе свято»',
        image: 'giftBox/0nash-ny-svyato.webp',
        price: 91,
    },
    content: [
        {
            ...candies.chervonyiMak,
            qantity: 3,
        },
        {
            ...candies.slivkiLenivki,
            qantity: 1,
        },
        {
            ...candies.candyNutArahis,
            qantity: 3,
        },
        {
            ...candies.romashka,
            qantity: 3,
        },
        {
            ...candies.milkySplash,
            qantity: 3,
        },
        {
            ...candies.sorrento,
            qantity: 3,
        },
        {
            ...candies.nuga,
            qantity: 3,
        },
        {
            ...candies.galeretkiApelsin,
            qantity: 3,
        },
        {
            ...candies.sunnyZhuk,
            qantity: 4,
        },
        {
            ...candies.lolipopYogurt,
            qantity: 3,
        },
        {
            ...candies.didMoroz,
            qantity: 1,
        }
    ],
};
