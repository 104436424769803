import React from 'react';
import { Router, Route, Switch, Redirect } from "react-router-dom";

import './App.css';
import Layout from './containers/Layout';
import WelcomePage from './containers/WelcomePage';
import { GiftBoxDetail } from './components/GiftBox';

function App(props) {
  return (
    <main>
      <Router history={props.history}>
        <Switch>
          <Route path='/' exact component={WelcomePage} />
          <Route path='/purchase' exact component={Layout} />
          <Route exact path={`/:id`} component={GiftBoxDetail} />
          <Route path='*' component={() => <Redirect to="/" />} />
        </Switch>
      </Router>
    </main>
  );
}

export default App;
